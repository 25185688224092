import React, {useState, useEffect} from 'react';
import { Container, Form, Button, Alert, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { uri } from '../utils';
import PhoneInput, {isValidPhoneNumber, isPossiblePhoneNumber} from 'react-phone-number-input';
import { GoogleLogin } from '@react-oauth/google';
import logo from "../shopperr.png";


const Register = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [cityid, setCityid] = useState('');
    const [isGoogle, setIsGoole] = useState(false);
    const [cities, setCities] = useState([]);
    const [transport, setTransport] = useState('moto');
    const navigate = useNavigate();

    const getCities = async () => {
      try{
        const req = await fetch(uri('/cities/dropdown'));
        const res = await req.json();
        if(req.status == 200){
          setCities(res);
          if(res.length > 0) setCityid(res[0].id)
        }
      }catch(e){

      }
    }

    const register = async (e) => {
        try{
          e.preventDefault()
            if(!email.trim() || !password.trim() || !password2.trim() || password !== password2 || !phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid) return;
            e.preventDefault();
            if(error) setError(null)
            setLoading(true);
            const req = await fetch(uri('/register'), {
                method: 'POST',
                body: JSON.stringify({email, password, phone: `${phone}`, name, transport,cityid}),
                headers: {'Content-Type': 'application/json'}
            });
            const res = await req.json();
            if(req.status === 200){
                localStorage.setItem('token', res.access_token)
                navigate(!res.verified ? "/otp" : "/profile", {replace:true});
            } 
            else if(req.status == 401){
              localStorage.setItem("token", res.access_token);
              navigate('/otp')
            } 
            else {
                setError(res.message);
                setLoading(false);
            }
        } catch(err) {
            console.log(err);
            setError("Server Error");
            setLoading(false);
        }
    }

    const googleSignin = async (credentials) => {
      try{
          if(error) setError(null)
          setLoading(true);
          const req = await fetch(uri('/sociallogin'), {
              method: 'POST',
              body: JSON.stringify({credentials, transport, phone: `${phone}`, cityid}),
              headers: {'Content-Type': 'application/json'}
          });
          const res = await req.json();
          if(req.status === 200){
              localStorage.setItem('token', res.access_token)
              navigate("/profile", {replace:true});
          } else {
              setError(res.message);
              setLoading(false);
          }
      } catch(err) {
          console.log(err);
          setError("Server Error");
          setLoading(false);
      }
    }

    useEffect(() => {
      getCities()
    },[])

  if(loading) return <Spinner />
  return (
    <div style={{marginTop:'5%',marginBottom:'1%' }}>
            {error && <Alert variant='danger' key='danger'>{error}</Alert>}
        <img src={logo} alt='trakitrak shoppers logo'  style={{height:55,width:370, objectFit:'contain' }} />
    
        <div style={{width:'100%',marginBottom:'5%',paddingLeft:'3%',alignSelf:'center',flexDirection:'column',height:'auto',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#e05b0e'}}>  
        <h1 style={{color:'white',fontFamily: 'Poppins',paddingBottom:15,paddingTop:15}}>Trakitrak Shopper</h1>
        <div style={{zIndex:10,padding:16,marginBottom:-16,color:'rgb(25, 25, 25)',backgroundColor:'white',borderRadius:8,boxShadow:'rgba(0, 0, 0, 0.2) 0px 1px 4px',minWidth:'50%'}}>
        {/* <div>
            <Form.Check checked={isGoogle} onChange={(e) => setIsGoole(e.target.checked)} type="switch" label="Google Signin" />
          </div> */}
        <div>
        {!isGoogle &&
        <>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control onChange={(e) => setEmail(e.target.value)} required type="email"  />
        </Form.Group>


        <Form.Group className='mt-3'>
          <Form.Label>First Name</Form.Label>
          <Form.Control onChange={(e) => setName(e.target.value)} required />
        </Form.Group>
        </>
        }
        <Form.Group className='mt-3'>
          <Form.Label>City</Form.Label>
          <Form.Select onChange={e => setCityid(e.target.value)} value={cityid}>
            {cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone ? phone : ''}
            defaultCountry='LB'
            initialValueFormat='national'
            className='form-control'
            onChange={setPhone}
          />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Transportation Type</Form.Label>
          <div>
            <input type='checkbox' checked={transport === 'moto'} onChange={(e) => setTransport(e.target.checked ? 'moto' : null)} style={{marginRight: 5}} />
            <Form.Label>Moto</Form.Label>
          </div>
          <div>
            <input type='checkbox' checked={transport === 'car'} onChange={(e) => setTransport(e.target.checked ? 'car' : null)} style={{marginRight: 5}} />
            <Form.Label>Car</Form.Label>
          </div>
        </Form.Group>

        {!isGoogle &&
        <>

        <Form.Group className='mt-3'>
          <Form.Label>Password <i style={{fontSize: 13, opacity: 0.8}}>(Use Same password as your User credentials)</i></Form.Label>
          <Form.Control onChange={(e) => setPassword(e.target.value)} required type="password"  />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control  onChange={(e) => setPassword2(e.target.value)} required type="password"  />
        </Form.Group>
        </>
        }
        
        {!isGoogle &&
        <Button onClick={register} disabled={!email.trim() || !password.trim() || !password2.trim() || password !== password2 || !phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid} variant="primary" type="submit" className='mt-4 tt-button'>
          Register
        </Button> }

        {isGoogle &&
        <div className={!phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid || !transport ? `google-btn-disabled` : 'google-btn'} style={{margin: '20px 0px 30px 0px'}}>
          <div style={{width: '75%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
           <GoogleLogin
             shape='circle'
             theme='outline'
             type='icon'
             size='large'

             onSuccess={credentialResponse => {
               console.log(credentialResponse);
               googleSignin(credentialResponse.credential);
             }}
             onError={() => {
               console.log('Login Failed');
             }}
           />
           <p style={{margin:0,marginLeft:5,fontSize:14}}>Google</p>
         </div> 
            </div>
         
        </div>
        }
 
        <p style={{marginTop:'2%'}}>Already have an account? <Link style={{color:'#e05b0e',textDecoration:'underline'}} to={'/login'}>Login</Link></p>

            </div>
        </div>
</div>

  
    {/* <Container className="mt-5">
      <h2 className="text-center">Trakitrak Shopper</h2>
      {error && <Alert variant='danger' key='danger'>{error}</Alert>}
      <Form onSubmit={register}>

          <div>
            <Form.Check checked={isGoogle} onChange={(e) => setIsGoole(e.target.checked)} type="switch" label="Google Signin" />
          </div>
        
        {!isGoogle &&
        <>
        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control onChange={(e) => setEmail(e.target.value)} required type="email"  />
        </Form.Group>


        <Form.Group className='mt-3'>
          <Form.Label>First Name</Form.Label>
          <Form.Control onChange={(e) => setName(e.target.value)} required />
        </Form.Group>
        </>
        }
        <Form.Group className='mt-3'>
          <Form.Label>City</Form.Label>
          <Form.Select onChange={e => setCityid(e.target.value)} value={cityid}>
            {cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mt-3">
          <Form.Label>Phone Number</Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone ? phone : ''}
            defaultCountry='LB'
            initialValueFormat='national'
            className='form-control'
            onChange={setPhone}
          />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Transportation Type</Form.Label>
          <div>
            <input type='checkbox' checked={transport === 'moto'} onChange={(e) => setTransport(e.target.checked ? 'moto' : null)} style={{marginRight: 5}} />
            <Form.Label>Moto</Form.Label>
          </div>
          <div>
            <input type='checkbox' checked={transport === 'car'} onChange={(e) => setTransport(e.target.checked ? 'car' : null)} style={{marginRight: 5}} />
            <Form.Label>Car</Form.Label>
          </div>
        </Form.Group>

        {!isGoogle &&
        <>

        <Form.Group className='mt-3'>
          <Form.Label>Password <i style={{fontSize: 13, opacity: 0.8}}>(Use Same password as your User credentials)</i></Form.Label>
          <Form.Control onChange={(e) => setPassword(e.target.value)} required type="password"  />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control  onChange={(e) => setPassword2(e.target.value)} required type="password"  />
        </Form.Group>
        </>
        }
        
        {!isGoogle &&
        <Button disabled={!email.trim() || !password.trim() || !password2.trim() || password !== password2 || !phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid} variant="primary" type="submit" className='mt-4 tt-button'>
          Register
        </Button> }

        {isGoogle &&
        <div className={!phone || !phone.trim() || !isValidPhoneNumber(phone) || !cityid || !transport ? `google-btn-disabled` : 'google-btn'} style={{margin: '20px 0px 30px 0px'}}>
          <GoogleLogin
            shape='circle'
            theme='filled_blue'
            type='standard'
            size='large'
            width={300}
            onSuccess={credentialResponse => {
              //console.log(credentialResponse);
              googleSignin(credentialResponse.credential);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
        }


      </Form>

      <div className="mt-3 mb-5 text-center">
        <p>Already have an account? <Link to={'/login'}>Login</Link></p>
      </div>

    </Container> */}
    </div>
  )
}

export default Register